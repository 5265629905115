import React from 'react';
import PropTypes from 'prop-types';
import { Box, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SupportMenuList from './SupportMenuList';

const useStyles = makeStyles((theme) => ({
  menuTitle: {
    padding: theme.spacing(1, 2)
  }
}));

const SupportMenu = React.forwardRef(
  function ({ isOpen, onClose, onSelect }, ref) {
    const classes = useStyles();

    return (
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={onClose}
        open={isOpen}
      >
        <Box className={classes.menuTitle}>
          <Typography variant="h5" color="textPrimary">
            Support
          </Typography>
        </Box>
        <SupportMenuList onSelectItem={onSelect} />
      </Popover>
    )
  }
);

SupportMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func
};

SupportMenu.displayName = 'SupportMenu';
export default SupportMenu;
