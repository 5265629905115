import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TopicPropTypes from './types/Topic';
import VideoCard from './VideoCard';
import Carousel from '../Carousel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block'
  },
  topic: {
    backgroundColor: '#04A4E21c',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),

    '& .MuiTypography-root': {
      fontWeight: 800,
    }
  },
  videoCarousel: {
    marginBottom: theme.spacing(3)
  }
}));

export default function TopicGroup({ topic, onPlayVideo }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.root}>
      <ListItemText className={classes.topic}>
        <Typography variant="h5" color="secondary">
          {topic.name}
        </Typography>
      </ListItemText>
      <Carousel
        infinite
        maxVisible={3}
        renderItem={(video) => (
          <VideoCard video={video} onPlay={onPlayVideo} />
        )}
        items={topic.videos}
        keyName="id"
        className={classes.videoCarousel}
      />
    </ListItem>
  );
}

TopicGroup.propTypes = {
  topic: PropTypes.shape(TopicPropTypes),
  onPlayVideo: PropTypes.func,
  orientation: PropTypes.oneOf(['row', 'column'])
};

TopicGroup.defaultProps = {
  orientation: 'row'
}
