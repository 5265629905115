import { useState, useEffect } from 'react';
import { useSettings, axios } from '@nsd/fe';
import { createTheme } from 'src/theme';
import fallBackThemes from 'src/constants/defaultTheme';
import { useCookie } from 'src/hooks/useCookie';
import { getCompanyCodeFromAuthToken } from 'src/utils/company';


/**
 * On a fresh visit (no settings in localstorage) the theme is null,
 * it will take the first theme out of the /appTheme response and apply that.
 * Should enable custom themed dealer portal to immediately have that theme applied after login
 * @returns {Object}
 */
const useInitialTheme = () => {
  const { settings } = useSettings();
  const [allThemes, setAllThemes] = useState(null);
  const [theme, setTheme] = useState(null);
  const token = useCookie('nsd.token');
  const detectDefaultThemePreference = () => fallBackThemes[0]?.name;

  const doesThemeExist = (themeIn, themeList) =>
    themeList.find((theme) => theme.name === themeIn);

  const generateTheme = ({ data } = {}) => {
    const themes = data?.themes ?? fallBackThemes;
    setAllThemes(themes);
    // If there is a theme set in settings attempt to use that
    const currentThemeName = settings?.theme || themes[0].name;

    if (themes && doesThemeExist(currentThemeName, themes)) {
      setTheme(createTheme({ ...settings, theme: currentThemeName }, themes));
    } else {
      setTheme(
        createTheme(
          { ...settings, theme: detectDefaultThemePreference() },
          themes
        )
      );
    }
  };

  useEffect(() => {
    const companyCode = getCompanyCodeFromAuthToken(token);
    axios.get('/appTheme', {
      params: {
        companyCode,
        defaultOnlyWhenMissing: companyCode ? true : false,
      },
    })
      .then(generateTheme)
      .catch(generateTheme);
  }, [settings, token]);

  return { theme, setTheme, allThemes };
};

export default useInitialTheme;
