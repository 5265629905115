import React from 'react';
import PropTypes from 'prop-types';
import { CONTENT_TYPE } from './constants';
import FAQPropTypes from './types/FAQ';
import TrainingContent from './TrainingContent';
import TopicPropTypes from './types/Topic';

export default function SupportDialogContent({ data }) {
  // switch (type) {
  //   case CONTENT_TYPE.faq.value:
  //     return <FAQContent faqList={data.faqList} />;
  //   case CONTENT_TYPE.videos.value:
  //   default:
  //     return <TrainingContent topics={data.videoData.topics} />
  // }

  return <TrainingContent topics={data.videoData.topics} />
}

SupportDialogContent.propTypes = {
  type: PropTypes.oneOf([
    // CONTENT_TYPE.faq.value, 
    CONTENT_TYPE.videos.value
  ]),
  data: PropTypes.shape({
    videoData: PropTypes.shape({
      topics: PropTypes.arrayOf(
        PropTypes.shape(TopicPropTypes)
      ),
    }),
    faqList: PropTypes.arrayOf(
      PropTypes.shape(FAQPropTypes)
    )
  }),
  isLoading: PropTypes.bool
};

SupportDialogContent.defaultProps = {
  searchResults: []
};

SupportDialogContent.displayName = 'SupportDialogContent';
