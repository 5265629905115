import jwtDecode from 'jwt-decode';
import find from 'lodash/find';
import { suiteIdentifiers } from 'src/constants/suiteIdentifiers';

export function getCompanyCodeFromAuthToken(token) {
  if (!token) {
    return null;
  }

  const decoded = jwtDecode(token);
  let companyCode = find(decoded.userCompanyCodes, (company) =>
    company.includes(suiteIdentifiers.DEALER_PORTAL)
  );

  if (typeof companyCode !== 'string') {
    companyCode = decoded.userCompanyCodes?.[0];
  }

  return companyCode;
}
