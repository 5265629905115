/* eslint-disable no-param-reassign */
import axios, { CancelToken, isCancel } from 'axios';
import { getEnvironment } from './env';

export const getNsdApiBaseUrl = () => {
  let nsdApiBaseUrl = process.env.REACT_APP_INSIGHT_QORE_API;
  return nsdApiBaseUrl;
};

const addCookieTokenToRequest = config => {
  config.headers.common = {
    ...config.headers.common,
    ...(document.cookie ? { Cookie: document.cookie } : {})
  };
};

const getAPIHeader = () => {
  let header;

  header = {
    common: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_NSD_API_KEY
    }
  };

  return header;
};
const nsdAPIHeader = getAPIHeader(getEnvironment());

const appInstance = axios.create({
  headers: nsdAPIHeader,
  withCredentials: true,
  baseURL: getNsdApiBaseUrl()
});

appInstance.interceptors.request.use(
  config => {
    addCookieTokenToRequest(config);

    return config;
  },
  error => Promise.reject(error)
);

export default appInstance;
export { CancelToken, isCancel };
