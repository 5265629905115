import React from 'react';
import PropTypes from 'prop-types';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CONTENT_TYPE } from './constants';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0
  },
  listItem: {
    padding: 0
  },
  listItemTxt: {
    margin: 0
  },
  listItemBtn: {
    padding: theme.spacing(1, 2),
    justifyContent: 'start',
    width: '100%',
    textTransform: 'capitalize',
    fontWeight: 400
  }
}));

export default function SupportMenuList({ onSelectItem }) {
  const classes = useStyles();

  /** @param {string} itemValue */
  const handleClick = (itemValue) => {
    return function () {
      onSelectItem(itemValue);
    }
  }

  return (
    <List className={classes.list}>
      {Object
        .values(CONTENT_TYPE)
        .map(contentType => (
          <ListItem key={contentType.value} className={classes.listItem}>
            <ListItemText 
              className={classes.listItemTxt}
              primary={
                <Button 
                  className={classes.listItemBtn} 
                  onClick={handleClick(contentType.value)}
                >
                  {contentType.label}
                </Button>
              }
            />
          </ListItem>
        ))
      }
    </List>
  );
}

SupportMenuList.propTypes = {
  onSelectItem: PropTypes.func
};

SupportMenuList.displayName = 'SupportMenuList';
