import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TrainingVideoPropTypes from './types/TrainingVideo';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    cursor: 'pointer',
    border: 0,
    textAlign: 'left'
  },
  thumbnail: {
    borderRadius: theme.spacing(1),
    border: '1px solid #e5e5e5'
  },
  title: {
    fontWeight: 600
  }
}));

export default function VideoCard({ video }) {
  const classes = useStyles();

  return (
    <a href={video.videoSrc} target="_blank" rel="noreferrer">
      <Card className={classes.root} component="button">
        <img
          src={video.thumbnailSrc}
          className={classes.thumbnail}
          width="100%"
          height="100%"
        />
        <Box>
          <Typography variant="h5" className={classes.title}>
            {video.title}
          </Typography>
          <Typography variant="caption">{video.length}</Typography>
        </Box>
      </Card>
    </a>
  )
}

VideoCard.propTypes = {
  video: PropTypes.shape(TrainingVideoPropTypes),
};
