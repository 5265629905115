import React from 'react';
import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import { Trello as ClaimsIcon } from 'react-feather';
import appIcon from './images/appIcon.png';
import TopBarAddOn from 'src/components/TopBarAddOn';
import DashboardLayout from 'src/layouts/DashboardLayout';

const MODULE = {
  SALES: 'sales-686fc727-e5b4-4848-83d9-33e17777616f',
  REPORTS: 'reporting-161fac57-d6a6-4628-8845-a048edf0d0f8',
  COMMISSION_TIER: 'commissionSetup-f65f7b8b-943b-45fa-ae7c-a7ce2474fc2a',
  COMMISSION_VOUCHER_VIEW: 'commissionVoucher-30fac5fa-152e-4685-bde1-6b5145b2f81d',
  MONTHLY_TERRITORY_VIEW: 'monthlyTerritoryReview-0622055a-cbb8-459a-8586-aa271d2b01d0'

};

const appConfig = {
  appId: 'insightQore-5ddc80c4-2772-4f82-a8dc-56a227bc1c97',
  appName: 'Sales',
  priority: 2,
  path: 'sales',
  icon: appIcon,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: '',
      view: lazy(() => import('src/views/sales/splash-view'))
    },
    {
      exact: true,
      path: 'monthly-territory-review',
      view: lazy(() => import('src/views/sales/monthly-territory-review'))
    },
    {
      exact: true,
      path: 'commission-voucher',
      view: lazy(() => import('src/views/sales/commission-voucher'))
    },

    {
      exact: true,
      path: 'commission-setup',
      view: lazy(() => import('src/views/commission-setup'))
    },
  ],

  topBarConfig: {
    dataContextOptions: {
      enableDataContextOverride: true,
      customJSX: <TopBarAddOn />
    },
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
    onDataContextChange: args => {
      console.log(args);
    }
  },
  navConfig: {
    navBarItems: [
      {

        subheader: 'Sales',
        items: [

          {
            title: 'Commission Voucher',
            href: 'commission-voucher',
            icon: ClaimsIcon,
            module: MODULE.COMMISSION_VOUCHER_VIEW
          },

          {
            title: 'Monthly Territory Review',
            href: 'monthly-territory-review',
            icon: ClaimsIcon,
            module: MODULE.MONTHLY_TERRITORY_VIEW
          }

        ]
      },

      {
        subheader: 'Tier Managment',
        items: [

          {
            title: 'Commission Setup',
            href: 'commission-setup',
            icon: ClaimsIcon,
            module: MODULE.COMMISSION_TIER
          }
        ]
      }

    ]
  }
};

export default appConfig;
