import { DashboardLayout, AuthGuard } from '@nsd/fe';
import appIcon from './images/appIcon.png';

// const MODULE = {
//   USERS: 'users-7293807b-815f-4fde-8f32-8a7dbfede045',
//   COMPANIES: 'companies-8cb0bd03-9872-4625-8626-3d7822841613',
//   ROLES: 'roles-a96c187c-5c95-4b86-9624-033970d3aae2',
//   PACKAGES: 'packages-213196f1-8879-48f7-88d6-76b6dc24c494',
//   SUITES: 'suites-d7b51bd8-1113-4c7d-b044-ea5e012b9b6b',
// };

const appConfig = {
  appId: 'admin-cf196b57-0774-4a2c-bff0-04a4f44d7f0d',
  appName: 'Admin',
  priority: 1,
  path: 'admin',
  icon: appIcon,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [

  ],
  // Copy for all apps
  topBarConfig: {
    //   dataContextOptions: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     key: PropTypes.any,
    //     value: PropTypes.any,
    //     name: PropTypes.string,
    //     secondLine: PropTypes.string,
    //   })
    // ),
    // onDataContextChange: PropTypes.func,
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'Assets',
        items: [

        ],
      },
    ],
  },
};

export default appConfig;
