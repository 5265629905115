import httpRequestHandler, { CancelToken, isCancel } from '../utils/httpRequestHandler';
import cacheService from './cacheService';

class InsightQoreService {
    /** @typedef {import('../services/cacheService').CacheService} CacheService */

    /** @type {CacheService} */
    #cacheService;

    #cancelTokens = [];

    /**
     * @param {CacheService} cacheService
     */
    constructor(cacheService) {
        this.#cacheService = cacheService;
    }

    setAxiosInterceptors = ({ onLogout }) => {
        httpRequestHandler.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    this.setSession(null);

                    if (onLogout) {
                        onLogout();
                    }
                }

                return Promise.reject(error);
            }
        );
    };

    setSession = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            httpRequestHandler.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
            localStorage.removeItem('accessToken');
            delete httpRequestHandler.defaults.headers.common.Authorization;
        }
    };

    getAccessToken = () => localStorage.getItem('accessToken');

    getCommissionVouchersBySalesPerson = async (salesperson, month, year) => {
        const { data } = await httpRequestHandler.get(`/insightqore/commission-report/${salesperson}/${month}/${year}`);
        return data;
    };

    getGetTieredCommissionSummaryBySalesRep = async (salesperson, month, year, atQuota, yoY) => {
        const { data } = await httpRequestHandler.get(`/insightqore/tiered-commission-summary/${salesperson}/${month}/${year}?atquota=${atQuota}&yoy=${yoY}`);
        return data;
    };

    getCommissionVouchersDataWithSalesPeriodsAndSalesMembers = async () => {
        const { data } = await httpRequestHandler.get('/insightqore/commission-report-sales-periods');
        return data;
    };

    getRoofTopSales = async (roofTopId, month, year) => {
        const { data } = await httpRequestHandler.get(`/insightqore/rooftop-sales/${roofTopId}/${month}/${year}`);
        return data;
    };

    getRoofTopSalesByProduct = async (roofTopId, month, year) => {
        const { data } = await httpRequestHandler.get(`/insightqore/rooftop-sales-by-product/${roofTopId}/${month}/${year}`);
        return data;
    };

    //#region Sales Team Management
    getSalesAgentRoles = async (name) => {
        let res = await httpRequestHandler.get('/sales-team-management/sales-roles');
        return res.data;
    };

    getSalesAgentCommissionTiers = async (name) => {
        let res = await httpRequestHandler.get(`/sales-team-management/sales-commission-tier/${name}`);
        return res.data;
    };

    getSalesTeamAttributes = async (name) => {
        let res = await httpRequestHandler.get('/sales-team-management/sales-agent');
        if (name) {
            res = await httpRequestHandler.get(`/sales-team-management/sales-agent/${name}`);
        }
        return res.data;
    };

    getSalesAgentCommissionOverrides = async (childId) => {
        let res = await httpRequestHandler.get(`/sales-team-management/sales-commission-overrides/${childId}`);
        return res.data;
    };



    createUpdateSalesTeamAttributes = async (teamMemberData) => {
        let res = await httpRequestHandler.post('/sales-team-management/sales-agent', teamMemberData);

        return res.data;
    };

    createUpdateTeamMemberTier = async (tierData) => {
        let res = await httpRequestHandler.post('/sales-team-management/sales-commission-tier', tierData);

        return res.data;
    };
    //#endregion

    //#region Monthly Territory Review

    getMtrRevenueSummary = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        try {
            const { data } = await httpRequestHandler.get(`/monthly-territory-review/revenue-summary/${salesperson}/${year}/${month}`);
            return data;
        } catch (error) {
            return [];
        }

    };

    getMtrRevenue = async (salesperson, month, year, view = '', viewId = '', agentId = '') => {
        if (salesperson === undefined) return [];


        try {
            const { data } = await httpRequestHandler.get(`/monthly-territory-review/revenue/${salesperson}/${year}/${month}?view=${view}&viewId=${viewId}&agentId=${agentId}`);
            return data;
        } catch (error) {
            return [];
        }
    };
    getMtrContractCounts = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(`/monthly-territory-review/contract-count/${salesperson}/${year}/${month}`);
        return data;
    };
    getMtrCancelCounts = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(`/monthly-territory-review/cancel-count/${salesperson}/${year}/${month}`);
        return data;
    };
    getMtrCustomerCosts = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(`/monthly-territory-review/customer-cost/${salesperson}/${year}/${month}`);
        return data;
    };
    getMtrDealerRetained = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(`/monthly-territory-review/dealer-retained/${salesperson}/${year}/${month}`);
        return data;
    };
    //#endregion

    /**
     * @param {import('axios').AxiosError} error
     * @returns {boolean}
     */
    isCancelError = (error) => isCancel(error)
}

const claimService = new InsightQoreService(cacheService);
claimService.setSession(claimService.getAccessToken());

export default claimService;
