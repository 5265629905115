import { v4 } from 'uuid';

export const CONTENT_TYPE = {
  videos: {
    label: 'Training Videos',
    value: 'video'
  },
};

export const TopicId = {
  FILING_CLAIMS: v4(),
  USER_MANAGEMENT: v4(),
};

export const supportContentData = {
  faqList: [
    {
      id: v4(),
      question: 'How do I file a claim?',
      answer: 'From the browse claims view, click the FILE A NEW CLAIM button in the top right.'
    },
    {
      id: v4(),
      question: 'How do I view the details of a claim?',
      answer: 'From the browse claims view, click on any claim to view the details.'
    },
    {
      id: v4(),
      question: 'How do I upload a document to a claim?',
      answer: 'From the claims detail view, click on the documents tab, select the document category you want to upload documents to and drag your file into the document buckets.'
    }
  ],
  videoData: {
    topics: [
      {
        id: TopicId.FILING_CLAIMS,
        name: 'Filing Claims',
        videos: [
          {
            id: v4(),
            title: 'Appearance Protection',
            length: '2:55',
            thumbnailSrc: '/static/images/ECP thumbnail.PNG',
            videoSrc: 'https://nationsafedrivers-my.sharepoint.com/:v:/p/rfuller/EVVOeTz-2iZFgli-CLahQSABD1AmCD9S-VOLf2CHIQNScw?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=AiVdNv'
          },
          {
            id: v4(),
            title: 'Tire And Wheel',
            length: '3:20',
            thumbnailSrc: '/static/images/tire wheel thumbnail.PNG',
            videoSrc: 'https://nationsafedrivers-my.sharepoint.com/:v:/p/rfuller/EQdADvwOz4JKi1Ka7aAWWOQBwTjEDcduO8curlEMJO21eQ?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=KypIX9'

          },
          {
            id: v4(),
            title: 'Key Replacement',
            length: '2:00',
            thumbnailSrc: '/static/images/key replace thumbnail.PNG',
            videoSrc: 'https://nationsafedrivers-my.sharepoint.com/:v:/p/rfuller/EWFkEH9r-0pEqx2-402_3fsBOzvEl7_GEiaK5uHulwaxEA?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=Nq9OY2'
          },
          {
            id: v4(),
            title: 'Windshield',
            length: '3:54',
            thumbnailSrc: '/static/images/windshield thumbnail.PNG',
            videoSrc: 'https://nationsafedrivers-my.sharepoint.com/:v:/p/rfuller/EWMB6r6SOztNusYQWqiw64EBpvlMx-MbAb4yZBxJ3PObYw?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=9ebSeP'
          },
          {
            id: v4(),
            title: 'Paintless Dent Repair',
            length: '3:17',
            thumbnailSrc: '/static/images/dent thumbnail.PNG',
            videoSrc: 'https://nationsafedrivers-my.sharepoint.com/:v:/p/rfuller/EVRVz-FSkKBDnB2_w3BHJHkBkLB6-BwH8bmSXXJTI2JqAg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=qJzHse'
          },
        ]
      },
      {
        id: TopicId.USER_MANAGEMENT,
        name: 'User Management',
        videos: [
          {
            id: v4(),
            title: 'Managing Users',
            length: '8:00',
            thumbnailSrc: '/static/images/user mgmt thumbnail.PNG',
            videoSrc: 'https://nationsafedrivers-my.sharepoint.com/:v:/p/rfuller/EZXGeA-pA5RKiCdN6k8ikpwBtTLjzxLDn6dT-OZccLaFBQ?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=o0rtXD'
          },
        ]
      },
    ],
  }
};