import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export default function Carousel({ 
  maxVisible, 
  renderItem, 
  items, 
  keyName, 
  infinite,
  className
}) {
  return (
    <Swiper
      navigation
      loop={infinite}
      slidesPerView={maxVisible}
      grid={{ rows: 1 }}
      spaceBetween={24}
      modules={[Navigation, Grid]}
      className={className}
    >
      {items.map(item => (
        <SwiperSlide key={item[keyName]}>
          {renderItem(item)}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

Carousel.propTypes = {
  maxVisible: PropTypes.number,
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  keyName: PropTypes.string.isRequired,
  infinite: PropTypes.bool,
  className: PropTypes.string
};

Carousel.defaultProps = {
  maxVisible: 3,
  infinite: true
};
