export const aboveQuotaGrowthRateRule = (commissionData, salesRepAttributes) => {


    const aboveQuota = commissionData.filter(
        commissionVoucherData => commissionVoucherData['rawYoy%'] >= salesRepAttributes.quotaPercent && commissionVoucherData['cmGp'] > 0 && commissionVoucherData['yr1'] === 0 && commissionVoucherData['noProd'] === 0
    );

    const concernYr1ORNoProdCommission = commissionData.filter(
        commissionVoucherData => {
            if ((commissionVoucherData['yr1'] === 1 || commissionVoucherData['noProd'] === 1) && commissionVoucherData['cy90'] > 0) {
                return true;
            }
        }
    );

    const aboveQuotaData = [...aboveQuota, ...concernYr1ORNoProdCommission];

    return aboveQuotaData;


};

export const underPerformingGrowthRateRule = (
    commissionVoucherData,
    salesRepAttributes
) => {


    return commissionVoucherData.filter(commissionData => {
        return (
            commissionData['cmGp'] > 0 && commissionData['yr1'] === 0 && commissionData['noProd'] === 0 &&
            commissionData['rawYoy%'] >= salesRepAttributes.midQuotaPercent &&
            commissionData['rawYoy%'] <= salesRepAttributes.quotaPercent
        );
    });

};

export const concernGrowthRateRule = (commissionData, salesRepAttributes) => {

    const currentMonthGrossProfit = commissionData.filter(
        commissionVoucherData => commissionVoucherData['cmGp'] <= 0
    );

    const concernCommission = commissionData.filter(
        commissionVoucherData =>
            (commissionVoucherData['rawYoy%'] < salesRepAttributes.midQuotaPercent || commissionVoucherData['cmGp'] <= 0)
            && commissionVoucherData['yr1'] === 0 && commissionVoucherData['noProd'] === 0
    );

    const concernYr1ORNoProdCommission = commissionData.filter(
        commissionVoucherData => {
            if ((commissionVoucherData['yr1'] === 1 || commissionVoucherData['noProd'] === 1) && commissionVoucherData['cy90'] <= 0) {
                return true;
            }

        }

    );

    const concernData = [...concernCommission, ...concernYr1ORNoProdCommission];

    return concernData;




};
