import React from 'react';
import PropTypes from 'prop-types';

const ThemeOptionsContext = React.createContext({});

export function ThemeOptionsProvider({ children, allThemes }) {
  return (
    <ThemeOptionsContext.Provider value={allThemes}>
      {children}
    </ThemeOptionsContext.Provider>
  );
}

ThemeOptionsProvider.propTypes = {
  children: PropTypes.node,
  allThemes: PropTypes.array,
};

export default ThemeOptionsContext;
