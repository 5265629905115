import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { HelpOutline as SupportIcon } from '@material-ui/icons';

const SupportButton = React.forwardRef(
  function SupportButton({ onClick }, ref) {
    return (
      <Tooltip ref={ref} title="Support">
        <IconButton 
          color="inherit" 
          onClick={onClick}
        >
          <SvgIcon>
            <SupportIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    )
  }
);

SupportButton.propTypes = {
  onClick: PropTypes.func
};

SupportButton.displayName = 'SupportButton';
export default SupportButton;