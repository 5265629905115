import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SupportContext = React.createContext(null);

export function SupportProvider({ children }) {
  const [video, setVideo] = useState(null);

  /**
   * @param {{
   *  id: number, 
   *  title: string, 
   *  length: string, 
   *  thumbnailSrc: string, 
   *  videoSrc: string
   * }} video 
   */
  const handleOpenVideoPlayback = (video) => {
    setVideo({...video, startFromTime: 0})
  }

  /**
   * @param {number} time
   */
  const handleUpdateVideoTime = (time) => {
    setVideo({...video, startFromTime: time})
  }

  const handleCloseVideoPlayback = () => {
    setVideo(null)
  }

  const context = {
    video,
    updateVideoTime: handleUpdateVideoTime,
    openVideoPlayback: handleOpenVideoPlayback,
    closeVideoPlayback: handleCloseVideoPlayback,
  };

  return (
    <SupportContext.Provider value={context}>
      {children}
    </SupportContext.Provider>
  );
}

SupportProvider.propTypes = {
  children: PropTypes.elementType,
};

export default SupportContext;
