import React, { useRef, useState } from 'react';
import SupportButton from './SupportButton';
import SupportDialog from './SupportDialog';
import SupportMenu from './SupportMenu';

export default function Support() {
  const buttonRef = useRef();
  const [contentType, setContentType] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleShowMenu = () => setIsMenuOpen(true);
  const handleHideMenu = () => setIsMenuOpen(false);

  /** @param {string} contentType */
  const handleChangeContentType = (contentType) => {
    setContentType(contentType);
    setIsDialogOpen(true);
    setIsMenuOpen(false);
  }

  const handleHideDialog = () => setIsDialogOpen(false);

  return (
    <React.Fragment>
      <SupportButton ref={buttonRef} onClick={handleShowMenu} />
      <SupportMenu 
        ref={buttonRef}
        isOpen={isMenuOpen} 
        onClose={handleHideMenu} 
        onSelect={handleChangeContentType} 
      />
      <SupportDialog 
        isOpen={isDialogOpen} 
        onClose={handleHideDialog} 
        initialTab={contentType} 
      />
    </React.Fragment>
  );
}
