import React, { useContext, useEffect } from 'react';
import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import Zoom from '@material-ui/core/Zoom';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import {
  makeStyles,
  jssPreset,
  createStyles,
  ThemeProvider,
  StylesProvider
} from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Auth,
  CacheProvider,
  FirebaseContext,
  SystemAlert,
  useFirebaseListener,
  CookiesNotification,
  GoogleAnalytics,
  ScrollReset,
  PORTAL_TYPE,
  setNotificationSettings,
  setApplicationDetails,
  setAlertSettings,
  SettingsNotification,
  Routes,
} from '@nsd/fe';
import apps from 'src/apps';

import useInitialTheme from 'src/hooks/useInitialTheme';
import { ThemeOptionsProvider } from 'src/context/ThemeOptionsContext';


const APP_NAME = 'Insight Qore';
const APP_END_USER = PORTAL_TYPE.CLIENT;
/* Used to determine loading/splash screen logo
Choices for APP_INTERNAL_NAME
[
  "dispatch-qore",
  "lender-qore",
  "client-qore",
  "provider-qore",
  "claims-qore",
  "contact-qore",
  "insight-qore",
  "sales-qore",
  "marketing-qore",
  "market-qore",
  "developer-qore",
  "pricebook-qore",
  "underwriter-qore",
  "integration-qore",
  "admin-qore",
  "driver-qore",
  "billing-qore",
  "agent-qore",
  "dealer-qore",
  "licensing-qore",
  "ledger-qore",
  "data-qore",
  "notifications-qore"
]
*/
const APP_INTERNAL_NAME = 'insight-qore';
const alertsConfigObject = {
  actionButtonContent: CancelPresentationIcon,
  // Used standard or filled
  variant: 'filled',
  position: 'top',
  transitionComponent: Zoom
};

const notificationsConfigObject = {
  pulseAnimation: true,
  displayNumberedBadge: true
};

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const GOOGLE_SCRIPT_LIBRARIES = ['drawing,places'];
const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      logo: {
        width: '100%',
        height: theme?.logo?.login?.height,
      },
    }
  })
);

const openSettingsWidget = () => {
  var node = document.querySelector('[title="Settings"]');
  node.click();
};

/**
 * @param {{dealerships: {dealerCode: string}[]}} newDealers 
 * @param {{active: boolean, dealerCode: string}[] | null} cachedDealers 
 * @returns {{dealerCode: string, active: boolean}[]}
 */


function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setApplicationDetails(
        APP_END_USER,
        APP_NAME,
        null,
        APP_INTERNAL_NAME
      )
    );
  }, [dispatch]);
  // Set alert and notification preferences / settings
  useEffect(() => {
    dispatch(setAlertSettings(alertsConfigObject));
    dispatch(setNotificationSettings(notificationsConfigObject));
  }, [dispatch]);

  // const { user, appSettings } = useSelector(state => state.account);

  // let [hasLoadedInitialAgents, setHasLoadedInitialAgents] = useState(false);
  // useEffect(() => {
  //   (async () => {
  //     if (user && !hasLoadedInitialAgents) {
  //       const isNsdAdmin = hasAccess(user.roles, ROLES.CLAIMS.NSD_ADMIN);
  //       const isSuperUser = isNsdAdmin || isRoamingAdmin(user.roles);


  //       dispatch(
  //         setUserData(
  //           { ...user, agents, isSuperUser },
  //           appSettings,
  //           user.roles,
  //           null
  //         )
  //       );
  //     }
  //   })();
  // }, [user]);

  const { FirebaseCrud } = useContext(FirebaseContext);
  // Pass in your customPath into hook
  // useFirebaseListener(true, true, 'mynotifpath/blah', 'myalertPath/blah');
  // Make this your own custom path to decide where in firestore to listen
  const generatedAlertsPath = FirebaseCrud.generateAlertsPath();
  const generatedNotificationsPath = FirebaseCrud.generateNotificationsPath();
  useFirebaseListener(
    true,
    true,
    generatedNotificationsPath,
    generatedAlertsPath
  );
  const classes = useStyles();
  const { theme, allThemes } = useInitialTheme();
  // Will silently fail without a Google API key

  /*
    Hook to debug notifications/alerts; shows the
    current paths set FirebaseCrud and FirebaseEvents and
    the currently set company.
    First arg -> How many sample alerts to create each render
    Second arg -> How many sample notifications to crete each render 
    useMessagingDebug(0, 0);  // In hooks folder
  */

  if (!theme) return null;
  return (
    <CacheProvider>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <ThemeOptionsProvider allThemes={allThemes}>
            <StylesProvider jss={jss}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <SnackbarProvider maxSnack={1}>
                  <Router history={history}>
                    <SystemAlert />
                    <Auth>
                      <ScrollReset />
                      <GoogleAnalytics />
                      <CookiesNotification />
                      <SettingsNotification
                        settingsPathFunc={openSettingsWidget}
                      />
                      <Routes
                        apps={apps || []}
                        logoConfig={{
                          logo: theme.logo?.login?.src,
                          logoAlt: 'Login Logo',
                          height: theme.logo?.login?.height,
                          className: classes.logo,
                        }}
                      />
                    </Auth>
                  </Router>
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeOptionsProvider>
        </ThemeProvider>
      </DndProvider>
    </CacheProvider>
  );
}

export default App;
