import insightQoreService from '../services/insightQoreService';
export const VOUCHER_BY_SALES = '@COMMISSIONVOUCHER/GET_COMM_VOUCHER_BY_SALES';

export const TIER_COMMISSION_SUMMARY =
  '@COMMISSIONVOUCHER/GET_COMM_TIER_COMMISSION_SUMMARY';
export const SELECTED_COMM_PERIOD = '@COMMISSIONVOUCHER/SELECTED_COMM_PERIOD';

export const VOUCHER_BY_SALES_PERIOD =
  '@COMMISSIONVOUCHER/GET_COMM_VOUCHER_BY_SALES_PERIOD';
export const SALES_TEAM = '@COMMISSIONVOUCHER/SALES_TEAM';
export const SALES_PERIODS = '@COMMISSIONVOUCHER/SALES_PERIODS';
export const SELECTED_SALES_TEAM_MEMBER =
  '@COMMISSIONVOUCHER/SELECTED_SALES_TEAM_MEMBER';
export const SELECTED_SALES_PERIOD = '@COMMISSIONVOUCHER/SELECTED_SALES_PERIOD';

export const MTR_SALES_SUMMARY = '@COMMISSIONVOUCHER/MTR_SALES_SUMMARY';
export const CLEAR_SALES_SUMMARY = '@COMMISSIONVOUCHER/CLEAR_SALES_SUMMARY';

export const MTR_NAVIGATATION_KEY_DATA = '@COMMISSIONVOUCHER/MTR_NAVIGATATION_KEY_DATA';


export function setMtrNavigationData(sourceData) {
  return async dispatch => {


    dispatch({
      type: MTR_NAVIGATATION_KEY_DATA,
      payload: { sourceData }
    });
  };
}

export function getCommissionVouchersBySalesPerson(period) {
  return async dispatch => {
    const { salesPerson, month, year } = period;
    const salesData = await insightQoreService.getCommissionVouchersBySalesPerson(
      salesPerson,
      month,
      year
    );

    dispatch({
      type: VOUCHER_BY_SALES,
      payload: { salesData, period }
    });
  };
}

export function getCommissionVouchersDataWithSalesPeriodsAndSalesMembers() {
  return async dispatch => {
    const result = await insightQoreService.getCommissionVouchersDataWithSalesPeriodsAndSalesMembers();

    let salesReps = salesReps => {
      let unique_values = salesReps
        .map(item => item.salesPerson)
        .filter(
          (value, index, current_value) =>
            current_value.indexOf(value) === index
        );
      return unique_values;
    };

    let uniqueSalesRep = salesReps(result);

    dispatch({
      type: VOUCHER_BY_SALES_PERIOD,
      payload: { salesCommissionData: result, uniqueSalesRep }
    });
  };
}

export function getTieredCommissionSummary(salesPerson, atQuota, yoY) {
  return async dispatch => {
    const result = await insightQoreService.getGetTieredCommissionSummaryBySalesRep(
      salesPerson,
      atQuota,
      yoY
    );

    dispatch({
      type: TIER_COMMISSION_SUMMARY,
      payload: { ...result }
    });
  };
}

export function clearSalesSummaryData() {

  return async dispatch => {

    dispatch({
      type: CLEAR_SALES_SUMMARY,
      payload: { result: [], grossProfit80Data: [], grossProfit20Data: [] }
    });

  };
}
export function getGrossProfitData(salesPerson, month, year) {
  return async dispatch => {
    const result = await insightQoreService.getMtrRevenue(salesPerson, month, year);

    let grossProfit80Data = result?.grossProfitDetail?.map(item => item)
      .filter(
        (value, index, current_value) =>
          value.isEighty === 1
      );

    let grossProfit20Data = result?.grossProfitDetail?.map(item => item)
      .filter(
        (value, index, current_value) =>
          value.isEighty === 0
      );

    dispatch({
      type: MTR_SALES_SUMMARY,
      payload: { result, grossProfit80Data, grossProfit20Data }
    });
  };
}




export function setSelectedSalesTeamMember(selectedTeamMember) {
  return async dispatch => {
    dispatch({
      type: SELECTED_SALES_TEAM_MEMBER,
      payload: selectedTeamMember
    });
  };
}

export function setSelectedPeriod(selectedPeriod) {
  localStorage.setItem('selectedSalesPeriod', JSON.stringify(selectedPeriod));
  return async dispatch => {
    dispatch({
      type: SELECTED_SALES_PERIOD,
      payload: selectedPeriod
    });
  };
}

export function setSalesPeriodList(salesPeriods) {

  return async dispatch => {

    dispatch({
      type: SALES_PERIODS,
      payload: salesPeriods
    });
  };
}
