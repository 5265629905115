import { combineReducers } from 'redux';
import { DEFAULT_REDUCERS } from '@nsd/fe';

import insightQoreReducer from './insightQoreReducer';

// Developer adds their own reducers before spreading
// in the DEFAULT_REDUCERS to not overwrite any needed keys
const rootReducer = combineReducers({
  ...DEFAULT_REDUCERS,
  insightQore: insightQoreReducer
});

export default rootReducer;
