import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, IconButton, List, SvgIcon } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import TopicGroup from './TopicGroup';
import TopicPropTypes from './types/Topic';
import VideoPlaybackView from './VideoPlaybackView';
import useSupportContext from 'src/hooks/useSupportContext';
import { TopicId } from './constants';

const TOPIC_VIEW = 'topic';
const PLAYBACK_VIEW = 'playback';

/**
 * @param {{id: number, name: string, createdAt: string, updatedAt: string}[]} roles 
 * @param {number} topicId 
 * @returns {boolean}
 */
function hasAccessToTopic(roles, topicId) {
  if (topicId !== TopicId.USER_MANAGEMENT) return true;

  const adminRoleId = process.env.REACT_APP_ADMIN_CORE_ROLE_ID;
  if (!roles.find(role => role.id == adminRoleId)) {
    return false;
  }

  return true;
}

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'sticky',
    top: -10,
    zIndex: 2,
    marginBottom: theme.spacing(1),

    '& .MuiTypography-root': {
      fontWeight: 700
    }
  }
}));

export default function TrainingContent({ topics }) {
  const classes = useStyles();

  const [view, setView] = useState(TOPIC_VIEW);
  const { video, openVideoPlayback, closeVideoPlayback } = useSupportContext();
  const { user: { roles } } = useSelector(state => state.account);

  const isPlaybackVisible = view === PLAYBACK_VIEW || video != null;

  const handlePlayVideo = (video) => {
    setView(PLAYBACK_VIEW);
    openVideoPlayback(video);
  }

  const handleGoBack = () => {
    closeVideoPlayback();
    setView(TOPIC_VIEW);
  }

  const ViewComponent = useMemo(() => {
    if (isPlaybackVisible) {
      return <VideoPlaybackView video={video} topics={topics} />
    }

    return (
      <List>
        {topics
          .filter((topic) => hasAccessToTopic(roles, topic.id))
          .map(topic => (
            <TopicGroup
              key={topic.id}
              topic={topic}
              onPlayVideo={handlePlayVideo}
            />
          ))
        }
      </List>
    );
  }, [isPlaybackVisible, topics])

  const BackButtonComponent = useMemo(() => {
    if (isPlaybackVisible) {
      return (
        <IconButton edge="start" onClick={handleGoBack}>
          <SvgIcon fontSize="small">
            <ArrowBack />
          </SvgIcon>
        </IconButton>
      );
    }
    return null;
  }, [isPlaybackVisible]);

  return (
    <Box component="section">
      <Box className={classes.titleWrapper}>
        {BackButtonComponent}
      </Box>
      {ViewComponent}
    </Box>
  )
}

TrainingContent.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape(TopicPropTypes)
  ),
};
