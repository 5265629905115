import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { CONTENT_TYPE, supportContentData } from './constants';
import SupportDialogContent from './SupportDialogContent';
import { SupportProvider } from 'src/context/SupportContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {},
  dialogTitle: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeBtn: {
    alignSelf: 'center'
  },
  supportNav: {
    display: 'flex'
  },
  dialogTablist: {
    width: 'fit-content',
    padding: theme.spacing(0, 3)
  },
  dialogTab: {
    padding: 0,
    minWidth: 'fit-content',
    marginRight: theme.spacing(5)
  },
  searchBox: {
    maxWidth: 400
  },
}));

export default function SupportDialog({ initialTab, isOpen, onClose }) {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(initialTab);
  const contentData = supportContentData

  useEffect(() => {
    setCurrentTab(initialTab)
  }, [isOpen]);

  return (
    <Dialog
      fullWidth
      className={classes.root}
      open={isOpen}
      maxWidth="lg"
      onClose={onClose}
    >
      <Box component="header" className={classes.header}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h2">
            <b>Knowledge Base</b>
          </Typography>
          <IconButton
            className={classes.closeBtn}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent>
        <SupportProvider>
          <SupportDialogContent type={currentTab} data={contentData} />
        </SupportProvider>
      </DialogContent>
    </Dialog>
  )
}

SupportDialog.propTypes = {
  initialTab: PropTypes.oneOf(
    Object.values(CONTENT_TYPE).map(value => value.value)
  ),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
