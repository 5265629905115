import React from 'react';
import useSupportContext from 'src/hooks/useSupportContext';

export default function VideoPlaybackView() {
  const { video } = useSupportContext();

  return (
    <iframe src={video.videoSrc} width="100%" height="600" />
  );
}
