import { useState, useEffect } from 'react';
import { parseCookie } from 'src/utils/cookie';

export function useCookie(cookieName) {
  const [cookie, setCookie] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentCookie = document.cookie;
      if (currentCookie != null) {
        const parsedCookie = parseCookie(currentCookie);

        if (parsedCookie[cookieName] != null) {
          setCookie(parsedCookie[cookieName]);
          clearInterval(interval);
        }
      }
    }, 500);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return cookie;
}